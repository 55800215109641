import Vue from 'vue';
import App from './App.vue';
import VueApexCharts from 'vue-apexcharts';
import VueClipboard from 'vue-clipboard2';
import VCalendar from 'v-calendar';
import ToggleButton from 'vue-js-toggle-button';
import router from './router';
import store from './store';
import i18n from './i18n';
import browserDetect from "vue-browser-detect-plugin";
import { lowerCase, globalDate } from '@/shared';
import { VeLoading, VeTable } from "vue-easytable";
import "vue-easytable/libs/theme-default/index.css";
import 'nprogress/nprogress.css';
Vue.use(VueApexCharts)
Vue.use(VueClipboard)
Vue.use(VCalendar, { componentPrefix: 'vc' })
Vue.use(ToggleButton)
Vue.use(VeTable)
Vue.use(browserDetect)
Vue.component('apexchart', VueApexCharts)



VueClipboard.config.autoSetContainer = true
Vue.filter('lowercase', lowerCase)
const globalProperties = {
  $veLoading: VeLoading,
  $imgUrl: 'https://pixel-vault.com/dticash_assets',
  $clickUrl: 'https://clear-tv.com',
  $contactUrl: 'https://service.d2pass.com/dticash/inquiry/',
  $month: globalDate.$month,
  $year: globalDate.$year,
  $today: globalDate.$today,
  $isInvoice: globalDate.$isInvoice()
}

Object.keys(globalProperties).forEach(key => {
  Vue.prototype[key] = globalProperties[key];
});
const savedLanguage = localStorage.selectLang;
const setLang = savedLanguage ? savedLanguage.replace(/"(.+)"/, "$1") : "en";

store.dispatch("locales/getLocaleActions", setLang);
if (process.env.NODE_ENV === 'development') {
  setTimeout(() => {
    console.log('Vue Devtools: 初期化')
    window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = Vue
  }, 1000)
}
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')