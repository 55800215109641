export const globalDate = {
  $month: new Date().getMonth() + 1,
  $year: new Date().getFullYear(),
  $today:new Date().getDate(),
  $isInvoice: function () {
    const now = new Date();
    const pacificTime = new Date(now.toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));
    const day = pacificTime.getDate();
    const hour = pacificTime.getHours();
    if (process.env.VUE_APP_ENV != "production") {
      return (day === 3 && hour >= 9) || (day > 5 && day < 30);
    } else {      
      return (day === 5 && hour >= 9) || (day > 5 && day < 16);
    }
  },
  $date: function () {
      if (typeof this.$month !== 'number' || typeof this.$year !== 'number' ||
          this.$month < 1 || this.$month > 12 || !Number.isInteger(this.$month )) {
        throw new Error("Invalid month or year");
      }

      if (this.$month === 1) {
        return {
          month: 12,
          year: this.$year - 1
        };
      } else {
        return {
          month: this.$month  - 1,
          year: this.$year
        };
      }
    }
  }