import { data } from '@/shared';

export default {
  namespaced: true,
  state: {
    pf: null,
    events: null,
    event: null
  },
  mutations: {
    updatePf(state, data) {
      state.performer = data;
    },
    updateEvents(state, data) {
      state.events = data;
    },
    updateEvent(state, data) {
      state.event = data;
    }
  },
  actions: {
    async getPfAction({ commit }) {
      const performer = await data.getDX("https://www.dxlive.com/event/thumbnailjsonUser/080124ran");
      commit("updatePf", performer);
    },
    async getEventsAction({ commit }) {
      console.log('getEventsAction')
      const events = await data.getDX("https://www.dxlive.01.stg.vc/json/event_images");
      commit("events", events);
    },
    async getEventAction({ commit }, url) {
      const event = await data.getDX(url );
      commit("event", event);
    }      
  }
}
