import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { data } from '@/locales/';

Vue.use(VueI18n)

function loadLocaleMessages() {

  const locales = data//require.context('./locales/', true, /.*\/.*\.json$/i)
  const messages = {}
  Object.keys(locales).forEach(key => {
    const locale = key
    messages[locale] = locales[key]
  })
  return messages
}

function checkDefaultLanguage() {
  let languages = Object.getOwnPropertyNames(loadLocaleMessages()),
  browserLang = navigator.language,
  browserLangShort = browserLang.split('-')[0];

  if (languages.includes(browserLang)) {
    return browserLang;
  }

  if (languages.includes(browserLangShort)) {
    console.log('Short match found:', browserLangShort);
    return browserLangShort;
  }

  console.log('No match found, defaulting to:', 'en');
  return 'en';
}
export const selectedLocale =  checkDefaultLanguage()

export const languages = Object.getOwnPropertyNames(loadLocaleMessages())

export default new VueI18n({
  locale: selectedLocale,
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})
